import React, { useState, useEffect, useRef } from 'react'

import styled from 'styled-components'

import Input from './input'

import Button from './button'

import { Container as PageContainer } from './styles'

import { CHANGE_PASSWORD } from './data-provider'

import { useMutation } from '@apollo/react-hooks'

import { isEmpty, get } from 'lodash-es'

import * as yup from 'yup' // for everything

import { usePasswordResetCode } from '../hooks'

const SuccessMessage = styled.div`
    font-size: 1.5rem;
    text-align: center;
    padding: 2rem;
    line-height: 150%;
`

const Container = styled(PageContainer)`
    min-height: 70vh;
`

const Title = styled.h1`
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
    font-weight: 400;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 5rem;
    }
`

const Form = styled.form``

async function validateInput(input) {
    const schema = yup.object().shape({
        password: yup.string().required(),
        passwordConfirmation: yup
            .string()
            .required()
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
    })
    return await schema.validate(input, { abortEarly: false })
}

export default () => {
    const [password, setPassword] = useState('')

    const [passwordConfirmation, setPasswordConfirmation] = useState('')

    const [validationErrors, setValidationErrors] = useState([])

    const [successMessage, setSuccessMessage] = useState(null)

    const [code] = usePasswordResetCode()

    const [changePassword, { data, loading }] = useMutation(CHANGE_PASSWORD)

    const form = useRef({})

    const getError = path => {
        return validationErrors.find(e => e.path === path)
    }

    useEffect(() => {
        const type = get(data, 'changePassword.type')
        const message = get(data, 'changePassword.message')
        if (type === 'ERROR') {
            setValidationErrors([{ message, path: 'password' }])
        } else if (type === 'SUCCESS') {
            setSuccessMessage(message)
        }
    }, [data])

    return successMessage ? (
        <Container>
            <SuccessMessage>{successMessage}</SuccessMessage>
            <div
                css={`
                    text-align: center;
                `}
            >
                <Button to="/login">Login</Button>
            </div>
        </Container>
    ) : (
        <Container>
            <Form
                ref={form}
                onSubmit={async e => {
                    e.preventDefault()
                    try {
                        setValidationErrors([])
                        await validateInput({ password, passwordConfirmation })
                        // do submit
                        changePassword({ variables: { password, code } })
                    } catch (e) {
                        setValidationErrors(e.inner)
                        console.log(e)
                    }
                }}
            >
                <Title>Change your password</Title>
                <Input
                    label="new password"
                    onChange={e => setPassword(e.target.value)}
                    error={getError('password')}
                    value={password}
                    placeholder="your password"
                    type="password"
                    disabled={loading}
                />
                <Input
                    label="confirm your password"
                    onChange={e => setPasswordConfirmation(e.target.value)}
                    error={getError('passwordConfirmation')}
                    value={passwordConfirmation}
                    type="password"
                    disabled={loading}
                    placeholder="password confirmation"
                />

                <Button
                    loading={loading}
                    disabled={
                        isEmpty(password) || isEmpty(passwordConfirmation)
                    }
                >
                    Save
                </Button>
            </Form>
        </Container>
    )
}
