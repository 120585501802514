import React from 'react'

import Layout from '../components/layout'

import SEO from '../components/seo'

import Footer from '../components/footer'

import Header from '../components/header'

import ChangePasswordForm from '../components/change-password-form'

export default () => {
    return (
        <Layout>
            <Header mode="site-header" />
            <SEO title={'Change your password'} />
            <ChangePasswordForm />
            <Footer />
        </Layout>
    )
}
